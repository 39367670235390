exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-dashboard-add-evaluacion-index-js": () => import("./../../../src/pages/dashboard/add-evaluacion/index.js" /* webpackChunkName: "component---src-pages-dashboard-add-evaluacion-index-js" */),
  "component---src-pages-dashboard-add-evaluacion-thumb-js": () => import("./../../../src/pages/dashboard/add-evaluacion/Thumb.js" /* webpackChunkName: "component---src-pages-dashboard-add-evaluacion-thumb-js" */),
  "component---src-pages-dashboard-add-productor-index-js": () => import("./../../../src/pages/dashboard/add-productor/index.js" /* webpackChunkName: "component---src-pages-dashboard-add-productor-index-js" */),
  "component---src-pages-dashboard-index-js": () => import("./../../../src/pages/dashboard/index.js" /* webpackChunkName: "component---src-pages-dashboard-index-js" */),
  "component---src-pages-dashboard-reportes-index-js": () => import("./../../../src/pages/dashboard/reportes/index.js" /* webpackChunkName: "component---src-pages-dashboard-reportes-index-js" */),
  "component---src-pages-dashboard-reportes-productor-cultivo-js": () => import("./../../../src/pages/dashboard/reportes/productor/cultivo.js" /* webpackChunkName: "component---src-pages-dashboard-reportes-productor-cultivo-js" */),
  "component---src-pages-dashboard-reportes-productor-descargar-index-js": () => import("./../../../src/pages/dashboard/reportes/productor/descargar/index.js" /* webpackChunkName: "component---src-pages-dashboard-reportes-productor-descargar-index-js" */),
  "component---src-pages-dashboard-reportes-productor-descargar-print-js": () => import("./../../../src/pages/dashboard/reportes/productor/descargar/print.js" /* webpackChunkName: "component---src-pages-dashboard-reportes-productor-descargar-print-js" */),
  "component---src-pages-dashboard-reportes-productor-index-js": () => import("./../../../src/pages/dashboard/reportes/productor/index.js" /* webpackChunkName: "component---src-pages-dashboard-reportes-productor-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-using-ssr-js": () => import("./../../../src/pages/using-ssr.js" /* webpackChunkName: "component---src-pages-using-ssr-js" */),
  "component---src-templates-using-dsg-js": () => import("./../../../src/templates/using-dsg.js" /* webpackChunkName: "component---src-templates-using-dsg-js" */)
}

